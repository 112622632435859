import { makeObservable, observable, action, computed, runInAction } from 'mobx';

import AlertViewModel from 'src/components/Alert/viewModel';
import MessageError from 'src/services/error/models/message';
import MessageService from 'src/services/message';
import QuillViewModel from 'src/components/Quill/viewModel';

import SwiperCardViewModel from './components/SwiperCard/viewModel';

export default class BannerSectionViewModel {
  @observable slideItems = [];
  @observable swiper;

  @observable isLoading = true;

  @observable alertVM = new AlertViewModel();

  @observable announcementTitle = '';
  @observable quillViewModel = new QuillViewModel();

  constructor() {
    makeObservable(this);
  }

  @action
  deserialize = (data) => {
    this.slideItems = data.map((el) => new SwiperCardViewModel(el, this));
  }

  @action
  updateData = (data) => {
    this.deserialize(data);
    this.swiper.updateSlides();
    if (this.slideItems.length <= 10) {
      this.swiper.slideTo(this.slideItems.length);
    } else {
      this.swiper.slideTo(this.slideItems.length - (this.slideItems.length - 10));
    }
    this.isLoading = false;
  }

  @action
  onSwiper = (swiper) => {
    this.swiper = swiper;
  }

  @action onNotificationClick = async (id) => {
    try {
      const { title, content } = await MessageService.getAnnouncement({ id });

      runInAction(() => {
        this.announcementTitle = title;
        this.quillViewModel.setQuillContent(content);

        this.alertVM.handlerOpenAlert();
      });
    } catch (error) {
      MessageError.getAnnouncement(error);
    }
  }

  @action
  handlerCloseAlert = () => {
    this.alertVM.handlerCloseAlert();
  }
}
